import React from "react";
import { graphql } from "gatsby";

import HeroHeading from "../components/HeroHeading";
import Team from "../components/Team";
import HelmetInfo from "../components/HelmetInfo";

const BoardOfDirectors = ({ data }) => {
  const { heading, subheading, team } = data.prismicBoardOfDirectors.data;
  return (
    <>
      <HelmetInfo page_data={data.prismicBoardOfDirectors.data} />
      <HeroHeading heading={heading} subheading={subheading} />
      <Team team={team} fromExecutive={false} />
    </>
  );
};

export default BoardOfDirectors;

export const query = graphql`
  query BoardDirectorsQuery {
    prismicBoardOfDirectors {
      data {
        heading
        subheading
        team {
          team_member {
            document {
              ... on PrismicPerson {
                uid
                data {
                  name
                  position
                  image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
